import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { kebabCase } from "lodash";

import { BlogPost } from "compositions";
import { Layout } from "containers";

const TemplatePost = ({
  data: {
    post: { data },
  },
  location,
  pageContext,
}) => {
  const layoutProps = {
    seoProps: {
      title: data.title,
      description: data?.description,
      openGraph: {
        title: data.title,
        description: data?.description,
        url: location?.href,
        type: "article",
        article: {
          publishedTime: new Date(data?.date).toISOString(),
          modifiedTime: new Date(data?.updateDate).toISOString(),
          section: data?.category,
          authors: [
            `${location?.origin}/about/#${kebabCase(data?.author?.name)}`,
          ],
        },
      },
    },
  };

  return (
    <Layout {...layoutProps}>
      <BlogPost pageContext={pageContext} path={`/blog/${data.id}`} {...data} />
    </Layout>
  );
};

TemplatePost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      data: PropTypes.shape({
        article: PropTypes.oneOfType([
          PropTypes.shape({
            childMdx: PropTypes.shape({
              body: PropTypes.string,
            }),
          }),
          PropTypes.string,
        ]),
        author: PropTypes.string,
        category: PropTypes.string,
        date: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.string,
        showComments: PropTypes.bool,
        title: PropTypes.string,
        updateDate: PropTypes.string,
      }),
    }),
  }),
};

export const postByIdQuery = graphql`
  query postById($id: String!) {
    post: airtable(data: { id: { eq: $id } }, table: { eq: "Posts" }) {
      data {
        article {
          childMdx {
            body
          }
        }
        author
        category
        date(formatString: "MMMM DD, YYYY")
        description
        id
        # showComments
        title
        updateDate(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;

export default TemplatePost;
